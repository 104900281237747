import styled, { css } from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { font } from '../../../styles/atoms/typography';

export const defaultSectionStyles = css`
    width: 100%;
    height: auto;
    padding: 48px 0px;

    ${atMinWidth.sm`
      padding: 56px 0px;
    `}

    ${atMinWidth.md`
      padding: 72px 0px;
    `}

    ${atMinWidth.xl`
      padding: 96px 0px;
    `}
`;

export const defaultContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
  max-width: 840px;
  margin: 0px auto;
  padding: 0px 23.5px;

  ${atMinWidth.sm`
    padding: 0px 35px;
  `}

  ${atMinWidth.xl`
    max-width: 1240px;
    flex-direction: row;
    gap: 24px;
  `}

  .submitted-message {
    ${font('display', 'sm', '700')}
    color: ${colors.green[600]};
  }
`;

export const cardSectionStyles = css`
  width: 100%;
  height: auto;
  padding: 40px 23.5px;
  background: transparent;

  ${atMinWidth.sm`
    padding: 40px 56px;
  `}

  ${atMinWidth.md`
    padding: 40px 0;
  `}
`;

export const cardContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 24px !important;
  background-color: ${colors.primary[50]};
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  ${atMinWidth.sm`
    padding: 48px 40px !important;
    gap: 40px;
  `}

  ${atMinWidth.md`
    max-width: 730px !important;
  `}

  ${atMinWidth.lg`
    max-width: 957px !important; 
  `}

  ${atMinWidth.xl`
    max-width: 1170px !important;
    flex-direction: row;
    gap: 0;
    padding: 68px 40px !important;
  `}

  .wave {
    pointer-events: none;

    > svg {
      position: absolute;
      top: initial;
      bottom: -15%;
      right: -10%;
      width: 120%;
      height: auto;

      ${atMinWidth.sm`
        bottom: -30%;
        right: -10%;
        width: 100%;
      `}

      ${atMinWidth.md`
        bottom: -22%;
        right: -5%;
        width: 60%;
      `}

      ${atMinWidth.lg`
        bottom: -35%;
        right: -10%;
        width: 65%;
      `}

      ${atMinWidth.xl`
        bottom: -30%;
        right: -5%;
        width: 45%;
      `}
    }
  }

  .submitted-message {
    ${font('text', 'md', '400')}
    max-width: 720px;
    text-align: center;
    margin: 0 auto;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    strong {
      color: ${colors.gray[900]};
    }

    h2 {
      ${font('display', 'md', '700')}
      margin-bottom: 16px;
    }

    h3 {
      ${font('display', 'sm', '700')}
      margin-bottom: 16px;
    }

    h4 {
      ${font('text', 'lg', '700')}
      margin-bottom: 20px;
    }

    > *:last-child {
      margin: 0;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  position: relative;

  ${atMinWidth.xl`
    min-width: ${props => (props.variant === 'card' ? '570px' : '0')};
  `}

  .heading-kicker {
    ${props =>
      props.variant === 'card'
        ? font('overline', 'md', '700')
        : font('overline', 'sm', '700')}
    ${props =>
      props.variant === 'card'
        ? `color: ${colors.primary[500]};`
        : `color: ${colors.base.white};`}
  }

  .heading {
    ${font('display', 'lg', '700')};
    ${props =>
      props.variant === 'default'
        ? `
          color: ${colors.base.white};
          ${font('display', 'lg', '700')}
        `
        : font('display', 'md', '700')}
  }
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  #reactHubspotForm0,  & > div, .hs-form-field, .input  {
    width: 100%;
  }

  .hs-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;

    ${atMinWidth.sm`
      flex-direction: row;
    `}
  }

  .hs-form-field label,
  .hs-form-field:not(.hs-email) {
    display: none;
  }

  .input {
    max-width: 100%;

    ${atMinWidth.xl`
      ${props =>
        props.variant === 'card'
          ? `
        max-width: 300px;
        margin-left: auto;
      `
          : ''}
    `}
  }

  .hs-input {
    ${props => (props.variant === 'card' ? shadow('xl') : shadow('xs'))}
    width: 100%;
    max-width: 100%;
    ${font('display', 'xs', '400')}
    color:  ${colors.gray[500]};
    background: ${colors.base.white};
    border: 1px solid ${props =>
      props.variant === 'card' ? 'transparent' : colors.gray[300]};
    border-radius: 8px;
    padding: 12px 14px;
    transition: all 200ms ease-in-out;
    font-size: 1.125rem;

    &::placeholder {
      ${font('display', 'xs', '400')}
      color: ${colors.gray[500]};
    }

    &:focus {
      border: 1px solid ${props =>
        props.variant === 'card' ? colors.primary[300] : colors.green[300]};
    }

    &.error {
      border: 1px solid ${colors.error[500]};
    }
  }

  .hs-submit {
    height: fit-content;
  }

  .hs-button {
    min-width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 16px;
    ${font('display', 'xs', '700')}
    background-color: ${props =>
      props.variant === 'card' ? colors.primary[500] : colors.green[600]};
    color: ${colors.base.white};
    border: 1px solid ${props =>
      props.variant === 'card' ? colors.primary[500] : colors.green[600]};
    border-radius: 6px;
    transition: all 200ms ease-in-out;

    &:hover {
      background-color: ${props =>
        props.variant === 'card' ? colors.primary[700] : colors.green[800]};
      border: 1px solid ${props =>
        props.variant === 'card' ? colors.primary[700] : colors.green[800]};
    }
  }

  .hs-error-msgs {
    list-style: none;
    padding-top: 8px;
    margin-bottom: 0px;

    ${atMinWidth.xl`
      ${props =>
        props.variant === 'card'
          ? `
        max-width: 300px;
        margin-left: auto;
      `
          : ''}
    `}
  }

  .hs-error-msg {
    display: block !important;
    ${font('text', 'sm', '600')}
    color: ${colors.error[500]};
  }

  .hs_error_rollup {
    display: none;
  }
`;
